import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import logo from '../../assets/images/logo.png';
import logo_white from '../../assets/images/logo_white.png';
import { toggleSidebarint, closeSidebar } from '../../store/actions/navActions';
import { sidebar_menu } from '../../config/Menu';
import { DARK_MODE } from '../../config/Constants';
import { RootState } from '../../store/reducer/rootReducer';
import { IMenuItemProps, IUserInfoProps, ITopMenuItemsProps } from '../services/GraphQLShared';
import { CommonResources, Resources } from '../../config/CommonResources';
import { Button } from '@progress/kendo-react-buttons';
import { UI_RELEASE_VERSION } from '../../config/Config';
import { OnDemandIcon } from '../../components/helpers/IconUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

const mapState = (state: RootState) => ({
  sidebarOpen: state.nav.sidebarOpen,
  profile: state.security.profile,
  theme: state.nav.theme,
});
const mapDispatch = {
  closeSidebar,
  toggleSidebarint,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type ISidebarProps = PropsFromRedux & {
  user: IUserInfoProps;
  isAuthenticated: boolean;
};
const data = sidebar_menu;

const NavSidebar = (props: ISidebarProps) => {
  const { toggleSidebar, collapseSidebar } = useProSidebar();
  let topMenuItems: ITopMenuItemsProps[] = [];
  if (props.profile) {
    topMenuItems = props.profile.DataAuth.TopMenuItems;
  }
  React.useEffect(() => {
    const closeSidebar = (e: any) => {
      if (e.code === 'Escape' && props.sidebarOpen) props.closeSidebar();
    };
    document.addEventListener('keydown', closeSidebar);
    return () => document.removeEventListener('keydown', closeSidebar);
  }, [props.sidebarOpen]);

  const filterData = data.filter(
    (e) =>
      e.parentId === undefined &&
      topMenuItems.some(
        (t) => t.Item1 == '' || (t.Item1 == e.key && (t.Item2 == e.subkey || t.Item2 == '')) || (t.Item1 == 'Framework' && (t.Item2 == e.key || t.Item2 == '')),
      ),
  );

  function clearAndNavigate() {
    props.toggleSidebarint();
    window['clearKendo'](true);
  }

  return (
    <Sidebar
      className="shadow"
      defaultCollapsed={props.sidebarOpen == false}
      transitionDuration={0}
      collapsedWidth={'0px'}
      breakPoint={'sm'}
    >
      <div className="sidebarHeader">
        <div
          className="ps-3 pt-3"
          style={{
            position: 'absolute',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start'
          }}
        >
          <Button
            type="button"
            className="k-icon-button me-4 close-icon"
            fillMode={'flat'}
            icon={'close'}
            onClick={() => {
              collapseSidebar();
              toggleSidebar();
              props.toggleSidebarint();
            }}
            style={{ padding: '5px' }}
          />
          <div className="d-flex flex-column">
            <img src={logo} className="side-menu-logo" alt={Resources.AppName} />
            <h4 className="ms-1 mb-0 mt-3 side-menu-appname">{Resources.ApplicationName} {Resources.ApplicationDescription}</h4>
          </div>
        </div>
      </div>
      <Menu style={{ marginTop: '7rem', paddingLeft: '3rem' }}>
        {filterData.map((e: IMenuItemProps, i: number) => (
          <NavSidebarMenu key={i} element={e} profile={props.profile} onClick={clearAndNavigate} />
        ))}
      </Menu>
    </Sidebar>
  );
};

export default React.memo(connector(NavSidebar));

function NavSidebarMenu(props): React.ReactElement {
  let topMenuItems: ITopMenuItemsProps[] = [];
  const navigate = useNavigate();
  if (props.profile) {
    topMenuItems = props.profile.DataAuth.TopMenuItems;
  }
  const { pathname } = useLocation();
  const { route } = props.element;
  const isRouteActive = (route === '/' && pathname === '/') || (route !== '/' && pathname.startsWith(route));
  const children = sidebar_menu.filter(
    (e) =>
      e.parentId === props.element.id &&
      topMenuItems.some(
        (t) =>
          t.Item1 == '' ||
          (((t.Item1 == props.element.key && (t.Item2 == props.element.subkey || t.Item2 == '')) ||
            (t.Item1 == 'Framework' && (t.Item2 == props.element.key || t.Item2 == ''))) &&
            (t.Item3 == '' || t.Item3 == e.key)),
      ),
  );

  const hasChildren = children.length > 0;
  if (hasChildren)
    return (
      <SubMenu
        label={props.element.title}
        className="p-0 pt-0"
        icon={<OnDemandIcon icon={props.element.icon} size={'lg'} />}
      >
        {children.map((e: IMenuItemProps, i: number) => (
          <NavSidebarMenu key={i} element={e} profile={props.profile} onClick={props.onClick} />
        ))}
      </SubMenu>
    );
  if (props.element.parentId)
    return (
      <MenuItem
        onClick={() => {
          navigate(props.element.route);
          props.onClick();
        }}
      >
        {props.element.title}
      </MenuItem>
    );
  return (
    <MenuItem
      className={cn(' ', { active: isRouteActive })}
      icon={<OnDemandIcon icon={props.element.icon} size={'lg'} />}
      onClick={() => {
        navigate(props.element.route);
        props.onClick();
      }}
    >
      {props.element.title}
    </MenuItem>
  );
}
