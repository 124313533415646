import { IMenuItemProps } from '../components/services/GraphQLShared';

export const sidebar_menu: IMenuItemProps[] = [
  { id: 0, title: 'Home', icon: 'home', route: '/', key: '' },
  {
    title: 'Master Data',
    key: 'Master',
    subkey: 'MDMMenu',
    icon: 'table',
    color: '#0a2756',
    route: '#',
    id: 1,
  },
  {
    title: 'Agencies',
    key: 'Company',
    icon: 'sitemap',
    route: '/Master/Company/Index',
    id: 2,
    parentId: 1,
  },
  {
    title: 'Clients',
    key: 'Client',
    icon: 'user',
    route: '/Master/Client/Index',
    id: 3,
    parentId: 1,
  },
  {
    title: 'Master Clients',
    key: 'MasterClient',
    icon: 'address-book',
    route: '/Master/MasterClient/Index',
    id: 4,
    parentId: 1,
  },
  {
    title: 'Media Classifications',
    key: 'MediaClassification',
    icon: 'flag',
    route: '/Master/MediaClassification/Index',
    id: 5,
    parentId: 1,
  },
  {
    title: 'Master Media Types',
    key: 'MasterMediaType',
    icon: 'life-ring',
    route: '/Master/MasterMediaType/Index',
    id: 6,
    parentId: 1,
  },
  {
    title: 'Master Vendor Owners',
    key: 'MasterVendorOwner',
    icon: 'shopping-cart',
    route: '/Master/MasterVendorOwner/Index',
    id: 7,
    parentId: 1,
  },
  {
    title: 'Media Owners',
    key: 'MediaOwner',
    icon: 'shopping-basket',
    route: '/Master/MediaOwner/Index',
    id: 8,
    parentId: 1,
  },
  {
    title: 'Media Vendors',
    key: 'MediaVendor',
    icon: 'shopping-bag',
    route: '/Master/MediaVendor/Index',
    id: 9,
    parentId: 1,
  },
  {
    title: 'Countries',
    key: 'Country',
    icon: 'globe',
    route: '/Master/Country/Index',
    id: 10,
    parentId: 1,
  },
  {
    title: 'Currencies',
    key: 'Currency',
    icon: 'dollar-sign',
    route: '/Master/Currency/Index',
    id: 11,
    parentId: 1,
  },
  {
    title: 'Languages',
    key: 'Language',
    icon: 'sign-language',
    route: '/Master/Language/Index',
    id: 12,
    parentId: 1,
  },
  {
    title: 'Markets',
    key: 'Market',
    icon: 'wifi',
    route: '/Master/Market/Index',
    id: 13,
    parentId: 1,
  },
  {
    title: 'Master Markets',
    key: 'MasterMarket',
    icon: 'rss',
    route: '/Master/MasterMarket/Index',
    id: 14,
    parentId: 1,
  },
  {
    title: 'Revenue Forecasts',
    key: 'Finance',
    subkey: 'RevenueForecastMenu',
    icon: 'calculator',
    color: '#0080ff',
    route: '#',
    id: 20,
  },
  {
    title: 'Revenue Forecast',
    key: 'RevenueForecast',
    icon: 'chart-line',
    route: '/Trading/RevenueForecast/Index',
    id: 21,
    parentId: 20,
  },
  {
    title: 'Revenue Streams',
    key: 'RevenueStream',
    icon: 'shopping-cart',
    route: '/Trading/RevenueStream/Index',
    id: 22,
    parentId: 20,
  },
  {
    title: 'Manual Streams',
    key: 'ManualStream',
    icon: 'hand-paper',
    route: '/Trading/ManualStream/Index',
    id: 23,
    parentId: 20,
  },
  {
    title: 'Client Profiles',
    key: 'ClientProfile',
    icon: 'user',
    route: '/Trading/ClientProfile/Index',
    id: 24,
    parentId: 20,
  },
  {
    title: 'ClientProfileGroup',
    key: 'ClientProfileGroup',
    icon: 'users',
    route: '/Trading/ClientProfileGroup/Index',
    id: 25,
    parentId: 20,
  },
  {
    title: 'Period Management',
    key: 'AgencyRevenuePeriod',
    icon: 'calendar-check',
    route: '/Trading/AgencyProfile/IndexRevenuePeriod',
    id: 26,
    parentId: 20,
  },
  {
    title: 'Forecast Audit Trail',
    key: 'ForecastAuditTrail',
    icon: 'bookmark',
    route: '/Trading/ForecastAuditTrail/IndexRevenue',
    id: 27,
    parentId: 20,
  },
  {
    title: 'Agency Profiles',
    key: 'AgencyProfile',
    icon: 'sitemap',
    route: '/Trading/AgencyProfile/IndexRevenue',
    id: 28,
    parentId: 20,
  },
  {
    title: 'Deal Forecasts',
    key: 'Trading',
    subkey: 'DealForecastMenu',
    icon: 'handshake',
    color: '#ffa800',
    route: '#',
    id: 30,
  },
  {
    title: 'DealForecasts',
    key: 'DealForecast',
    icon: 'chart-bar',
    route: '/Trading/DealForecast/Index',
    id: 31,
    parentId: 30,
  },
  {
    title: 'Master Client Profiles',
    key: 'MasterClientProfile',
    icon: 'address-book',
    route: '/Trading/MasterClientProfile/Index',
    id: 32,
    parentId: 30,
  },
  {
    title: 'Media Owner Profiles',
    key: 'MediaOwnerProfile',
    icon: 'shopping-cart',
    route: '/Trading/MediaOwnerProfile/Index',
    id: 33,
    parentId: 30,
  },
  {
    title: 'Deal Commitments',
    key: 'DealCommitment',
    icon: 'balance-scale',
    route: '/Trading/DealCommitment/Index',
    id: 34,
    parentId: 30,
  },
  {
    title: 'Deal Categories',
    key: 'DealCategory',
    icon: 'sliders-h',
    route: '/Trading/DealCategory/Index',
    id: 35,
    parentId: 30,
  },
  {
    title: 'Period Management',
    key: 'AgencyDealPeriod',
    icon: 'calendar-check',
    route: '/Trading/AgencyProfile/IndexDealPeriod',
    id: 36,
    parentId: 30,
  },
  {
    title: 'Forecast Audit Trail',
    key: 'DealForecastAuditTrail',
    icon: 'bookmark',
    route: '/Trading/ForecastAuditTrail/IndexDeal',
    id: 37,
    parentId: 30,
  },
  {
    title: 'Agency Profiles',
    key: 'DealAgencyProfile',
    icon: 'sitemap',
    route: '/Trading/AgencyProfile/IndexDeal',
    id: 38,
    parentId: 30,
  },
  {
    title: 'Ordered and Billed',
    key: 'Trading',
    subkey: 'IntegrationTradingMenu',
    icon: 'chalkboard',
    color: '#00b5b1',
    route: '#',
    id: 40,
  },
  {
    title: 'Manual Spends',
    key: 'ManualSpends',
    icon: 'hand-paper',
    route: '/Trading/RevenueForecast/IndexManual',
    id: 41,
    parentId: 40,
  },
  {
    title: 'Ordered Spends',
    key: 'OrderedSpend',
    icon: 'shopping-basket',
    route: '/Trading/OrderedSpend/Index',
    id: 42,
    parentId: 40,
  },
  {
    title: 'Billed Spends',
    key: 'BilledSpend',
    icon: 'shopping-cart',
    route: '/Trading/BilledSpend/Index',
    id: 42,
    parentId: 40,
  },
  {
    title: 'Spend Accounts',
    key: 'SpendAccount',
    icon: 'signal',
    route: '/Trading/SpendAccount/Index',
    id: 42,
    parentId: 40,
  },
  {
    title: 'Spend Report',
    key: 'IntegrationReporting',
    icon: 'file-excel',
    route: '/Report/Index/SpendReports',
    id: 42,
    parentId: 40,
  },
  {
    title: 'Bulk Operations',
    key: 'BulkControlPanel',
    icon: 'universal-access',
    route: '/Trading/AgencyProfile/BulkPanel',
    id: 42,
    parentId: 40,
  },
  {
    title: 'Canada Spend Dashboard',
    key: 'Reporting',
    subkey: 'CanadaSpendDashboard',
    icon: 'chart-pie',
    color: '#ef5b2b',
    route: '#',
    id: 50,
  },
  {
    title: 'Owner Dashboard',
    key: 'CanadaOwnerDashboard',
    icon: 'chess-board',
    route: '/Trading/DealForecast/OwnerDashboard',
    id: 51,
    parentId: 50,
  },
  {
    title: 'Media Spend Analysis',
    key: 'MediaSpendAnalysis',
    icon: 'chart-bar',
    route: '/Trading/DealForecast/MediaSpend',
    id: 52,
    parentId: 50,
  },
  {
    title: 'Media Spend Trends',
    key: 'MediaSpendTrend',
    icon: 'chart-line',
    route: '/Trading/DealForecast/MediaSpendTrail',
    id: 53,
    parentId: 50,
  },
  {
    title: 'Market Dashboard',
    key: 'MarketDashboard',
    icon: 'globe',
    route: '/Trading/DealForecast/MarketDashboard',
    id: 54,
    parentId: 50,
  },
  {
    title: 'Client Dashboard',
    key: 'ClientsDashboard',
    icon: 'user-circle',
    route: '/Trading/DealForecast/ClientDashboard',
    id: 55,
    parentId: 50,
  },
  {
    title: 'Owner Word Cloud',
    key: 'OwnerWordCloud',
    icon: 'cloud',
    route: '/Trading/DealForecast/OwnerWordCloud',
    id: 56,
    parentId: 50,
  },
  {
    title: 'Crosstab',
    key: 'Crosstab',
    icon: 'sitemap',
    route: '/Trading/DealForecast/Crosstab',
    id: 57,
    parentId: 50,
  },
  {
    title: 'Revenue Tracker Dashboard',
    key: 'Reporting',
    subkey: 'RevenueTrackerDashboard',
    icon: 'chart-line',
    color: '#001e62',
    route: '#',
    id: 60,
  },
  {
    title: 'Dashboard',
    key: 'Dashboard',
    icon: 'tachometer-alt',
    route: '/Trading/RevenueForecast/Dashboard',
    id: 61,
    parentId: 60,
  },
  {
    title: 'Data Validation',
    key: 'DataValidation',
    icon: 'binoculars',
    route: '/Trading/RevenueForecast/DataValidation',
    id: 62,
    parentId: 60,
  },
  {
    title: 'GAD Analysis',
    key: 'GadAnalysis',
    icon: 'user-circle',
    route: '/Trading/RevenueForecast/GadAnalysis',
    id: 63,
    parentId: 60,
  },
  {
    title: 'Audit Trail',
    key: 'AuditTrail',
    icon: 'search',
    route: '/Trading/RevenueForecast/AuditTrail',
    id: 64,
    parentId: 60,
  },
  {
    title: 'Deal Manager Dashboard',
    key: 'Reporting',
    subkey: 'DealManagerDashboard',
    icon: 'chart-bar',
    color: '#0080ff',
    route: '#',
    id: 70,
  },
  {
    title: 'Deal Analysis',
    key: 'DealAnalysis',
    icon: 'balance-scale',
    route: '/Trading/DealCommitment/DealAnalysis',
    id: 71,
    parentId: 70,
  },
  {
    title: 'Projection Crosstab',
    key: 'ProjectionCrosstab',
    icon: 'chart-line',
    route: '/Trading/DealCommitment/ProjectionCrosstab',
    id: 72,
    parentId: 70,
  },
  {
    title: 'Total Media Spend vs Forecasts',
    key: 'SpendsVsForecast',
    icon: 'chart-line',
    route: '/Trading/DealCommitment/SpendsVsForecast',
    id: 73,
    parentId: 70,
  },
  {
    title: 'Forecasts',
    key: 'DealForecasts',
    icon: 'chart-bar',
    route: '/Trading/DealCommitment/DealForecasts',
    id: 74,
    parentId: 70,
  },
  {
    title: 'Forecast Audit Trail',
    key: 'ForecastAuditTrail',
    icon: 'search',
    route: '/Trading/DealCommitment/ForecastAuditTrail',
    id: 75,
    parentId: 70,
  },
  {
    title: 'Agency Forecast Tracker Dashboard',
    key: 'Reporting',
    subkey: 'AgencyTrackerDashboard',
    icon: 'chart-line',
    route: '#',
    id: 80,
  },
  {
    title: 'Forecast Benchmarking',
    key: 'ForecastBenchmarking',
    icon: 'tachometer-alt',
    route: '/Trading/DealCommitment/ForecastBenchmarking',
    id: 81,
    parentId: 80,
  },
  {
    title: 'Forecasts',
    key: 'AgencyForecasts',
    icon: 'chart-line',
    route: '/Trading/DealCommitment/AgencyForecasts',
    id: 82,
    parentId: 80,
  },
  {
    title: 'Deal Manager',
    key: 'DealManager',
    icon: 'balance-scale',
    route: '/Trading/DealCommitment/DealManager',
    id: 83,
    parentId: 80,
  },
  {
    title: 'Security',
    key: 'SecurityMenu',
    icon: 'users',
    route: '#',
    id: 100,
  },
  {
    title: 'Roles',
    key: 'Roles',
    icon: 'tablet-alt',
    description: 'Manage security roles',
    route: '/Framework/Role/Index',
    id: 101,
    parentId: 100,
  },
  {
    title: 'Security Groups',
    key: 'SecurityGroups',
    icon: 'tablet-alt',
    description: 'Manage security groups',
    route: '/Framework/Group/Index',
    id: 102,
    parentId: 100,
  },
  {
    title: 'Users',
    key: 'Users',
    icon: 'tablet-alt',
    description: 'Manage users',
    route: '/Framework/User/Index',
    id: 103,
    parentId: 100,
  },
  {
    title: 'Users and Groups',
    key: 'UsersandGroups',
    icon: 'tablet-alt',
    description: 'Wizard for users management',
    route: '/Framework/User/IndexTree',
    id: 104,
    parentId: 100,
  },
  {
    title: 'Security Reports',
    key: 'SecurityReports',
    icon: 'tablet-alt',
    description: 'User Reports',
    route: '/Report/Index/Security',
    id: 105,
    parentId: 100,
  },
  {
    title: 'Integration',
    key: 'IntegrationMenu',
    icon: 'broadcast-tower',
    route: '#',
    id: 141,
  },
  {
    title: 'Definitions',
    key: 'Definitions',
    icon: 'tablet-alt',
    description: 'Manage integration types',
    route: '/Framework/IntegrationDefinition/Index',
    id: 142,
    parentId: 141,
  },
  {
    title: 'Messages and Processes',
    key: 'MessagesandProcesses',
    icon: 'tablet-alt',
    description: 'Check integration logs',
    route: '/Framework/IntegrationProcess/Index',
    id: 143,
    parentId: 141,
  },
  {
    title: 'Administration',
    key: 'AdminMenu',
    icon: 'wrench',
    route: '#',
    id: 200,
  },
  {
    title: 'Documents',
    key: 'Documents',
    icon: 'tablet-alt',
    description: 'Manage all documents',
    route: '/Framework/DocumentFile/Index',
    id: 201,
    parentId: 200,
  },
  {
    title: 'Application Settings',
    key: 'ApplicationSettings',
    icon: 'tablet-alt',
    description: 'Set application settings',
    route: '/Framework/AppSettings/Index',
    id: 202,
    parentId: 200,
  },
  {
    title: 'Data Administration',
    key: 'DataAdministration',
    icon: 'tablet-alt',
    description: 'Query all data',
    route: '/Framework/DataAdmin/Index',
    id: 203,
    parentId: 200,
  },
  {
    title: 'Insights Viewer',
    key: 'InsightsViewer',
    icon: 'tablet-alt',
    description: 'Query application logs',
    route: '/Framework/InsightsViewer/Index',
    id: 204,
    parentId: 200,
  },
];
export const app_themes = [
  { id: 'light', text: 'Light' },
  { id: 'dark', text: 'Dark' },
];
